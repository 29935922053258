
import { useCountdown } from '@/composables/useCountDown'
import { Nui } from '@/types/Nui'
import NuiBetGroup from '@/components/multi-table/portrait/nui-bet-group.vue'
import {
    ChipItem,
    PropType,
    TableData,
    computed,
    defineComponent,
    ref,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['lock', 'on-commision-change'],
    props: {
        display: {
            required: false,
            type: Number as PropType<number>,
            default: -1
        },
        desk: {
            type: Object as PropType<TableData>,
            required: true
        },
        chip: {
            type: Object as PropType<ChipItem>,
            require: false
        }
    },
    components: {
        'm-nui-bet-group': NuiBetGroup
    },
    setup(props, { emit }) {
        const { t } = useI18n()

        const showVideo = ref(false)
        const isVideoPreviewOn = ref(false)
        const nuiList = ref<Nui[]>([])
        const { currentCount, startCountDown, stopCountDown } = useCountdown()

        const hasTempBet = ref(false)
        const toggleVideo = () => {
            //
        }

        const confirmTempBet = () => {
            //
        }

        const cancelTempbet = () => {
            //
        }

        const lock = () => {
            const copyData = { ...props.desk }
            copyData.expand.daoJiShiBetTime = currentCount.value
            if (currentCount.value <= 0) copyData.status = 2
            emit('lock', copyData)
        }
        const tableName = computed(() => {
            const { tableNo } = props.desk
            // const name = props.desk.gameTypeNo.toUpperCase()

            // if (name === 'G002') return `${t('baccarattable')}${tableNo}`
            // else if (name === 'G003')
            //     return `${t('dragontigertable')}${tableNo}`
            // else return `牛牛 ${tableNo}`
            return t('desklist.niuniutable', { tablenumber: tableNo })
        })

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })

        watch(
            () => props.desk,
            (d) => {
                if (d && d.expand.resultList.length > 0) {
                    nuiList.value = Nui.ParseList(
                        d.expand.resultList as Array<string>,
                        7
                    )
                }
            },
            { immediate: true, deep: true }
        )

        return {
            tableName,
            currentCount,
            timerColor,
            showVideo,
            isVideoPreviewOn,
            hasTempBet,
            nuiList,
            toggleVideo,
            cancelTempbet,
            confirmTempBet,
            lock,
            t
        }
    }
})
