import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-047fab8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-chip"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "bet-box-root",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.placeBet && _ctx.placeBet(...args)))
  }, [
    _createElementVNode("div", {
      class: "chips-wrap",
      style: _normalizeStyle(_ctx.chipWrapStyle)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chipsUI, (chip, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "chip chip_bet_box_ui",
          key: index,
          style: _normalizeStyle(_ctx.chipStyle(chip.x, chip.y)),
          "data-chip": chip.class
        }, null, 12, _hoisted_1))
      }), 128))
    ], 4),
    _renderSlot(_ctx.$slots, "label", {
      data: _ctx.amount,
      class: "label"
    }, undefined, true)
  ]))
}