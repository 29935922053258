import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ba30d13"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "multi-bet-chip-control-root" }
const _hoisted_2 = { class: "multi-bet-chip-control-list-wrap" }
const _hoisted_3 = { class: "multi-bet-chip-control-list-item" }
const _hoisted_4 = ["data-value", "onClick"]
const _hoisted_5 = { class: "multi-bet-chip-control-other-group" }
const _hoisted_6 = { class: "fill-row" }
const _hoisted_7 = { class: "money-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_dolar = _resolveComponent("svg-dolar")!
  const _directive_dragscroll = _resolveDirective("dragscroll")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chipDefault, (chip) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["chip-item", [
                        { active: chip.id === _ctx.activeChipId },
                        `chip-${chip.id}`
                    ]]),
            key: chip.id,
            "data-value": _ctx.abbreviateNumber(chip.value),
            onClick: _withModifiers(($event: any) => (_ctx.selectChip(chip.id)), ["prevent"])
          }, null, 10, _hoisted_4))
        }), 128))
      ])), [
        [
          _directive_dragscroll,
          true,
          void 0,
          { x: true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_svg_dolar, { class: "svg-dolar" }),
          _createElementVNode("span", null, _toDisplayString(_ctx.coin), 1)
        ])
      ]),
      _createElementVNode("div", {
        class: "button-chip-setting",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.showChipDialog && _ctx.showChipDialog(...args)))
      })
    ])
  ]))
}