
import {
    computed,
    defineComponent,
    onBeforeUnmount,
    PropType,
    ref,
    TableData,
    watch
} from 'vue'

import RoadMap from '@/components/roadmap/index.vue'
import { useI18n } from 'vue-i18n'
import { useCountdown } from '@/composables/useCountDown'
import { RoadMapData } from './roadmap/datamodel/RoadMapData'
import { parseRoadMap } from './roadmap/utils'
import { ROADMAP_RECOMADATION } from '@/types/global'

export default defineComponent({
    emits: ['unlock'],
    props: {
        mData: {
            type: Object as PropType<TableData>,
            required: true
        }
    },
    components: {
        'road-map': RoadMap
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const goodWayLabel = ref('')
        const blinkTimeOut = ref<number | boolean | any>(false)
        const mapdata = ref<RoadMapData>(parseRoadMap([]))

        const { currentCount, startCountDown, stopCountDown } = useCountdown()

        //#region Vue Helpers
        onBeforeUnmount(() => {
            stopCountDown()
        })
        //#endregion

        const onRoadMapDataParsed = (a: RoadMapData) => {
            mapdata.value = a

            if (mapdata.value.goodway.length > 0) {
                // goodWayLabel.value = ROADMAP_RECOMADATION.toLabels(
                //     mapdata.value.goodway[0].id,
                //     props.mData.gameTypeNo.toUpperCase() === 'G002' ? 1 : 2
                // )

                goodWayLabel.value = t(
                    `goodroad.${ROADMAP_RECOMADATION.toLabels(
                        mapdata.value.goodway[0].id,
                        props.mData.gameTypeNo.toUpperCase() === 'G002' ? 1 : 2
                    )}`
                )

                blinkTimeOut.value = setTimeout(() => {
                    if (typeof blinkTimeOut.value === 'number') {
                        stopGoodWayBlink()
                    }
                }, 3000)
            }
        }

        const stopGoodWayBlink = () => {
            if (typeof blinkTimeOut.value === 'number') {
                clearTimeout(blinkTimeOut.value)
                blinkTimeOut.value = false
            }
        }

        const unlock = () => {
            const copyData = { ...props.mData }
            copyData.expand.daoJiShiBetTime = currentCount.value
            if (currentCount.value <= 0) copyData.status = 2
            emit('unlock', copyData)
        }

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })
        const tableName = computed(() => {
            const { tableNo, gameHallType } = props.mData

            let _tableName = ''
            if (props.mData.gameTypeNo.toUpperCase() === 'G002')
                _tableName = `${t('desklist.baccarattable', {
                    tablenumber: tableNo
                })}`
            else
                _tableName = `${t('desklist.dragontigertable', {
                    tablenumber: tableNo
                })}`

            if (gameHallType === 2)
                _tableName = t('desklist.classic') + _tableName

            return _tableName
        })

        watch(
            () => props.mData,
            (_v) => {
                if (_v.expand.daoJiShiBetTime > 0) {
                    startCountDown(_v.expand.daoJiShiBetTime)
                } else {
                    stopCountDown()
                }
            },
            { immediate: true }
        )

        return {
            tableName,
            currentCount,
            goodWayLabel,
            blinkTimeOut,
            timerColor,
            onRoadMapDataParsed,
            unlock,
            t
        }
    }
})
