
import {
    ChipItem,
    PropType,
    TableData,
    computed,
    defineComponent,
    ref,
    watch
} from 'vue'
import { useI18n } from 'vue-i18n'
import { SvgVideo } from '@/components/icons'
import { Nui } from '@/types/Nui'
import { useCountdown } from '@/composables/useCountDown'
import NuiBetGroup from './nui-bet-group.vue'

export default defineComponent({
    emits: ['enter-table', 'lock', 'stop-betting', 'on-commision-change'],
    props: {
        display: {
            required: false,
            type: Number as PropType<number>,
            default: -1
        },
        desk: {
            type: Object as PropType<TableData>,
            required: true
        },
        chip: {
            type: Object as PropType<ChipItem>,
            require: false
        }
    },
    components: {
        'svg-video': SvgVideo,
        'nui-bet-group': NuiBetGroup
    },
    setup(props, { emit }) {
        const { t } = useI18n()

        const isLoadingVideo = ref<boolean>(true)
        const isVideoOn = ref(false)
        const isVideoPreviewOn = ref(false)
        const isCommissionOn = ref(true)

        const { currentCount, startCountDown, stopCountDown } = useCountdown()

        const nuiList = ref<Nui[]>([])

        const hasTempBet = ref(false)

        const toggleVideo = () => {
            //
        }

        const toggleTableCommission = () => {
            //
        }

        const cancelTempbet = () => {
            //
        }

        const confirmTempBet = () => {
            //
        }

        const lock = () => {
            const copyData = { ...props.desk }
            copyData.expand.daoJiShiBetTime = currentCount.value
            if (currentCount.value <= 0) copyData.status = 2
            emit('lock', copyData)
        }

        const gameType = computed(() => {
            if (props.desk) return props.desk.gameTypeNo === 'G002' ? 1 : 2
            return 1
        })

        const tableName = computed(() => {
            const { tableNo } = props.desk
            // const name = props.desk.gameTypeNo.toUpperCase()

            // if (name === 'G002') return `${t('baccarattable')}${tableNo}`
            // else if (name === 'G003')
            //     return `${t('dragontigertable')}${tableNo}`
            // else return `牛牛 ${tableNo}`

            return t('desklist.niuniutable', { tablenumber: tableNo })
        })

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })

        watch(
            () => props.desk,
            (_v) => {
                if (_v.expand.daoJiShiBetTime > 0) {
                    startCountDown(_v.expand.daoJiShiBetTime)
                } else {
                    stopCountDown()
                }

                if (_v && _v.expand.resultList.length > 0) {
                    nuiList.value = Nui.ParseList(
                        _v.expand.resultList as Array<string>,
                        7
                    )
                }
            },
            { immediate: true }
        )

        return {
            tableName,
            isVideoOn,
            isLoadingVideo,
            isVideoPreviewOn,
            nuiList,
            currentCount,
            timerColor,
            isCommissionOn,
            gameType,
            hasTempBet,
            toggleVideo,
            toggleTableCommission,
            lock,
            t,
            cancelTempbet,
            confirmTempBet
        }
    }
})
