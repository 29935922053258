
//#region Imports
import {
    IconAllRoad,
    IconBigRoad,
    IconMore,
    IconStatistics,
    IconBack,
    IconThumbsUp4,
    IconLock2,
    IconReload2,
    IconNotice,
    SvgRecord,
    SvgExclamation,
    SvgDolar,
    SvgFullScreenToggle,
    SvgSetting,
    IconUserInfo,
    IconDolar,
    SvgArrowDown2
} from '@/components/icons'
import { EventBuss, SOCKET_MESSAGE } from '@/types/global'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'
import {
    computed,
    defineComponent,
    Emitter,
    HallData,
    inject,
    LimitOption,
    onBeforeMount,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    ResponseData,
    ResultMemberList,
    SocketMessage,
    TableData,
    UserInfo,
    watch
} from 'vue'
import { VTextMarquee } from 'vue-text-marquee'
import MobileChipsControl from '@/components/mobile-chip-control.vue'
import MobileListItem from '@/components/mobile-multi-betting-item.vue'
import { useStore } from '@/store'
import DOtherMap from '@/components/d-other-map.vue'
import DMultibetChipControl from '@/components/multi-bet-chip-control.vue'
import DMultiBettingItem from '@/components/d-multibeting-item.vue'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import Dropdown from '@/components/drop-down.vue'
import { AppMutation } from '@/store/types'
import { getHallData, getMultiTableDetail, switchLimit } from '@/api'
import MobileSelectTables from '@/components/multi-table/portrait/select-tables.vue'
import DUserInfo from '@/components/d-user-info.vue'
import DOtherMapNui from '@/components/multi-table/landscape/select-table-item-niu.vue'
import TableItemNui from '@/components/multi-table/landscape/table-item-niu.vue'
import MobileTableItemNui from '@/components/multi-table/portrait/table-item-nui.vue'
import { useI18n } from 'vue-i18n'
//#endregion

export default defineComponent({
    props: {
        hall: {
            type: Number as PropType<number>,
            deafault: 1
        }
    },
    components: {
        'marquee-text': VTextMarquee,
        'icon-all-road': IconAllRoad,
        'icon-big-road': IconBigRoad,
        'icon-statistics': IconStatistics,
        'icon-more': IconMore,
        'icon-back': IconBack,
        'icon-thumbs-4': IconThumbsUp4,
        'icon-lock-2': IconLock2,
        'icon-reload': IconReload2,
        'icon-notice': IconNotice,
        'm-chips-control': MobileChipsControl,
        'm-list-item': MobileListItem,
        'svg-record': SvgRecord,
        'svg-exclamation': SvgExclamation,
        'svg-dolar': SvgDolar,
        'icon-dolar': IconDolar,
        'svg-full-screen-toggle': SvgFullScreenToggle,
        'svg-setting': SvgSetting,
        'svg-arrow-down-2': SvgArrowDown2,
        IconUserInfo,
        'd-other-map': DOtherMap,
        'd-other-map-nui': DOtherMapNui,
        'd-multibet-chip-control': DMultibetChipControl,
        'd-multi-betting-item': DMultiBettingItem,
        'dropdown': Dropdown,
        'mobile-select-tables': MobileSelectTables,
        'd-user-info': DUserInfo,
        'table-item-nui': TableItemNui,
        'mobile-table-item-nui': MobileTableItemNui
    },
    setup() {
        const emitter = inject('emitter') as Emitter
        const store = useStore()
        const router = useRouter()
        // const route = useRoute()
        const { t } = useI18n()
        const noticeKey = ref(new Date().getTime())
        const displayOption = ref(0)
        const listKey = ref<number>(new Date().getTime())
        const showMoreInfo = ref(false)
        const desks = ref<TableData[]>([])
        const deskSelection = ref<TableData[]>([])

        const limitOption = ref(-1)
        const selectedGameType = ref(0)
        const gameTypesOptions = ref([
            t('loby.allgames'),
            t('common.baccarat'),
            t('common.dragontiger')
        ])

        const showSelectTable = ref(false)

        const lobyNotice = ref('')

        const chip = ref()

        const showAllMenu = ref(process.env.SHOW_ALL_MENU === '0')

        let loadDataFailedCounter = 0
        const tableVideo = ref(-1)

        //#region Vue Helpers

        onMounted(() => {
            // console.log(limitOptionArray.value)
            // set multitables

            tableVideo.value = multiTableVideo.value

            reloadData()
        })

        onBeforeMount(() => {
            emitter.on(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.on(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.SOCKET_MESSAGE, handleSocketMessage)
            emitter.off(EventBuss.PAGE_ON_VISIBLE, handleOnPageActive)
        })

        //endregion

        //#region Socket Messages Helpers

        const handleOnPageActive = () => {
            loadMultiDesk(getMultitableIds())
            loadData(true)
        }

        const reloadData = () => {
            // load multitables
            loadMultiDesk(getMultitableIds())

            // load data
            loadData()
        }
        const handleSocketMessage = (_message: SocketMessage | any) => {
            const { type } = _message
            const _type = type.toUpperCase()

            if (
                _type === SOCKET_MESSAGE.NEW_GAME ||
                _type === SOCKET_MESSAGE.END_GAME ||
                _type === SOCKET_MESSAGE.STOP_GAME ||
                _type === SOCKET_MESSAGE.NEW_SHOE
            ) {
                const sDesk = _message.gameTable as TableData
                // let _index = -1
                // let _desk = desks.value.find(
                //     (_d) => _d.id === _message.gameTable!.id
                // )

                // console.log(_message.gameTable)

                let _index = desks.value.findIndex(
                    (_d) => _d.id === _message.gameTable!.id
                )

                // check if its inside the desks collection
                if (_index !== -1) {
                    const _desk = desks.value[_index]

                    if (_type === SOCKET_MESSAGE.NEW_GAME) {
                        const _copyDesk = { ...desks.value[_index] }

                        _copyDesk.expand.shoeNo = sDesk.expand.shoeNo
                        _copyDesk.expand.juNo = sDesk.expand.juNo
                        _copyDesk.expand.gameNo = sDesk.expand.gameNo
                        _copyDesk.expand.daoJiShiBetTime =
                            sDesk.expand.daoJiShiBetTime

                        // reset all data
                        _copyDesk.expand.betFormList = []
                        _copyDesk.expand.xianAmount = 0
                        _copyDesk.expand.xianDuiAmount = 0
                        _copyDesk.expand.zhuangAmount = 0
                        _copyDesk.expand.zhuangDuiAmount = 0
                        _copyDesk.expand.heAmount = 0

                        // reset card result
                        _copyDesk.expand.playerOne = undefined
                        _copyDesk.expand.playerTwo = undefined
                        _copyDesk.expand.playerThree = undefined
                        _copyDesk.expand.bankerOne = undefined
                        _copyDesk.expand.bankerTwo = undefined
                        _copyDesk.expand.bankerThree = undefined
                        _copyDesk.expand.playerCount = undefined
                        _copyDesk.expand.bankerCount = undefined
                        _copyDesk.expand.whoWin = -1

                        _copyDesk.status = 1

                        desks.value[_index] = _copyDesk
                        // console.log(desks.value[_index].expand.resultList)
                    } else if (_type === SOCKET_MESSAGE.STOP_GAME) {
                        const _copyDesk = {
                            ...desks.value[_index]
                        }
                        _copyDesk.expand.daoJiShiBetTime = 0
                        _copyDesk.status = 2
                        _copyDesk.expand.resultList =
                            desks.value[_index].expand.resultList
                        desks.value[_index] = _copyDesk
                    } else if (_type === SOCKET_MESSAGE.END_GAME) {
                        const _copyDesk = {
                            ...desks.value[_index]
                        }

                        _copyDesk.expand.playerOne = sDesk.expand.playerOne
                        _copyDesk.expand.playerTwo = sDesk.expand.playerTwo
                        _copyDesk.expand.playerThree = sDesk.expand.playerThree
                        _copyDesk.expand.bankerOne = sDesk.expand.bankerOne
                        _copyDesk.expand.bankerTwo = sDesk.expand.bankerTwo
                        _copyDesk.expand.bankerThree = sDesk.expand.bankerThree
                        _copyDesk.expand.playerCount = sDesk.expand.playerCount
                        _copyDesk.expand.bankerCount = sDesk.expand.bankerCount
                        _copyDesk.expand.whoWin = sDesk.expand.whoWin
                        _copyDesk.expand.resultList = sDesk.expand.resultList
                        _copyDesk.expand.gameRecordList =
                            sDesk.expand.gameRecordList

                        // reset bet data
                        _copyDesk.expand.betFormList = []
                        _copyDesk.expand.xianAmount = 0
                        _copyDesk.expand.xianDuiAmount = 0
                        _copyDesk.expand.zhuangAmount = 0
                        _copyDesk.expand.zhuangDuiAmount = 0
                        _copyDesk.expand.heAmount = 0

                        const memberResultList = sDesk.expand
                            .resultMemberList as ResultMemberList[]
                        if (memberResultList && memberResultList.length > 0) {
                            const _memberResultInfo = memberResultList.find(
                                (x) => x.memberId === memberId.value
                            )

                            if (_memberResultInfo) {
                                store.commit(
                                    AppMutation.SET_COIN,
                                    _memberResultInfo.amount
                                )

                                if (_memberResultInfo.winResult !== 0) {
                                    let _amtStr = '0'

                                    if (_memberResultInfo.winResult > 0)
                                        _amtStr = `+${_memberResultInfo.winResult}`
                                    else
                                        _amtStr = String(
                                            _memberResultInfo.winResult
                                        )

                                    emitter.emit(EventBuss.SMALL_TOAST, {
                                        msg: _amtStr,
                                        id: _copyDesk.id
                                    })
                                }
                                emitter.emit(
                                    EventBuss.MULTI_END_GAME,
                                    _copyDesk.id
                                )
                            }
                        } else {
                            // console.log(getMultitableIds())
                            emitter.emit(EventBuss.MULTI_END_GAME, _copyDesk.id)
                        }

                        desks.value[_index] = _copyDesk
                    } else if (_type === SOCKET_MESSAGE.NEW_SHOE) {
                        desks.value[_index] = sDesk
                    }
                }
                // not update  desk selection
                else {
                    _index = deskSelection.value.findIndex(
                        (_d) => _d.id === _message.gameTable!.id
                    )

                    // _desk = deskSelection.value.find((x) => x.id === sDesk.id)
                    if (_index !== -1) {
                        const _desk = { ...deskSelection.value[_index] }
                        if (_type === SOCKET_MESSAGE.NEW_GAME) {
                            _desk.expand.shoeNo = sDesk.expand.shoeNo
                            _desk.expand.juNo = sDesk.expand.juNo
                            _desk.expand.gameNo = sDesk.expand.gameNo
                            _desk.expand.daoJiShiBetTime =
                                sDesk.expand.daoJiShiBetTime

                            // reset all data
                            _desk.expand.betFormList = []
                            _desk.expand.xianAmount = 0
                            _desk.expand.xianDuiAmount = 0
                            _desk.expand.zhuangAmount = 0
                            _desk.expand.zhuangDuiAmount = 0
                            _desk.expand.heAmount = 0

                            // reset card result
                            _desk.expand.playerOne = undefined
                            _desk.expand.playerTwo = undefined
                            _desk.expand.playerThree = undefined
                            _desk.expand.bankerOne = undefined
                            _desk.expand.bankerTwo = undefined
                            _desk.expand.bankerThree = undefined
                            _desk.expand.playerCount = undefined
                            _desk.expand.bankerCount = undefined
                            _desk.expand.whoWin = -1

                            _desk.status = 1
                            deskSelection.value[_index] = _desk
                        } else if (_type === SOCKET_MESSAGE.STOP_GAME) {
                            _desk.expand.daoJiShiBetTime = 0
                            _desk.status = 2
                            deskSelection.value[_index] = _desk
                        } else if (_type === SOCKET_MESSAGE.END_GAME) {
                            _desk.expand.playerOne = sDesk.expand.playerOne
                            _desk.expand.playerTwo = sDesk.expand.playerTwo
                            _desk.expand.playerThree = sDesk.expand.playerThree
                            _desk.expand.bankerOne = sDesk.expand.bankerOne
                            _desk.expand.bankerTwo = sDesk.expand.bankerTwo
                            _desk.expand.bankerThree = sDesk.expand.bankerThree
                            _desk.expand.playerCount = sDesk.expand.playerCount
                            _desk.expand.bankerCount = sDesk.expand.bankerCount
                            _desk.expand.whoWin = sDesk.expand.whoWin
                            _desk.expand.resultList = sDesk.expand.resultList
                            _desk.expand.gameRecordList =
                                sDesk.expand.gameRecordList

                            // reset bet data
                            _desk.expand.betFormList = []
                            _desk.expand.xianAmount = 0
                            _desk.expand.xianDuiAmount = 0
                            _desk.expand.zhuangAmount = 0
                            _desk.expand.zhuangDuiAmount = 0
                            _desk.expand.heAmount = 0
                            deskSelection.value[_index] = _desk
                        } else if (_type === SOCKET_MESSAGE.NEW_SHOE) {
                            deskSelection.value[_index] = sDesk
                        }
                    }
                }
            } else if (_type === SOCKET_MESSAGE.CANCEL_GAME) {
                const sDesk = _message.gameTable
                const _table = desks.value.find((_d) => _d.id === sDesk.id)

                if (_table) {
                    const _tableIndex = desks.value.indexOf(_table)
                    const _copyDesk = {
                        ..._table
                    }

                    _copyDesk.expand.daoJiShiBetTime = -1
                    _copyDesk.expand.betFormList = []
                    _copyDesk.expand.xianAmount = 0
                    _copyDesk.expand.xianDuiAmount = 0
                    _copyDesk.expand.zhuangAmount = 0
                    _copyDesk.expand.zhuangDuiAmount = 0
                    _copyDesk.expand.heAmount = 0

                    const memberResultList = sDesk.expand
                        .resultMemberList as ResultMemberList[]

                    if (memberResultList && memberResultList.length > 0) {
                        const _memberResultInfo = memberResultList.find(
                            (x) => x.memberId === memberId.value
                        )

                        if (_memberResultInfo) {
                            store.commit(
                                AppMutation.SET_COIN,
                                _memberResultInfo.amount
                            )

                            setTimeout(() => {
                                emitter.emit(
                                    EventBuss.MULTI_CANCEL_GAME,
                                    _copyDesk.id
                                )
                            }, 250)
                        }

                        desks.value[_tableIndex] = _copyDesk
                    }
                }
            } else if (_type === SOCKET_MESSAGE.BET) {
                const sDesk = _message.gameTable
                const _table = desks.value.find((_d) => _d.id === sDesk.id)
                if (_table) {
                    const _tableIndex = desks.value.indexOf(_table)
                    const _copyDesk = {
                        ..._table
                    }

                    _copyDesk.expand.xianAmount = sDesk.expand.xianAmount
                    _copyDesk.expand.xianDuiAmount = sDesk.expand.xianDuiAmount
                    _copyDesk.expand.zhuangAmount = sDesk.expand.zhuangAmount
                    _copyDesk.expand.zhuangDuiAmount =
                        sDesk.expand.zhuangDuiAmount
                    _copyDesk.expand.heAmount = sDesk.expand.heAmount
                    if (sDesk.expand.memberId === memberId.value) {
                        _copyDesk.expand.betFormList = sDesk.expand.betFormList
                        store.commit(AppMutation.SET_COIN, sDesk.expand.amount)
                    }

                    desks.value[_tableIndex] = _copyDesk
                }
            }
        }

        //#endregion

        //#region Private Helpers

        const setTableVideo = (_tableId: number) => {
            tableVideo.value = _tableId

            store.commit(AppMutation.SET_MULTI_TABLE_VIDEO, _tableId)
        }

        const handleLoadItems = (num: number[]) => {
            // const baccarat = num.indexOf(0) !== -1
            // const dtiger = num.indexOf(1) !== -1
            // const nui = num.indexOf(2) !== -1

            // if (baccarat && dtiger && nui) {
            //     selectedGameType.value = 0
            // }
            // console.log(num)
            // if (num.length === 1) {
            //     if (num[0] === 0) {
            //         selectedGameType.value = 1
            //     } else if (num[0] == 1) {
            //         selectedGameType.value = 2
            //     } else if (num[0] === 2) {
            //         selectedGameType.value = 3
            //     }
            // }else if(num.length === 2){
            //     if(num[0]===)

            // }
            // else {
            //     selectedGameType.value = 0
            // }
            loadData()
        }
        const loadData = async (_forceReload = false) => {
            if (!_forceReload) store.commit(AppMutation.SHOW_LOADING)

            const _params: any = {
                token: token.value,
                gameHallType: 0
            }

            // if (!showAllMenu.value) {
            //     _params.gameHallType = 1
            // }

            // if (selectedGameType.value === 1) _params.gameTypeNo = 'G002'
            // else if (selectedGameType.value === 2) _params.gameTypeNo = 'G003'

            getHallData(_params)
                .then((response) => {
                    loadDataFailedCounter = 0
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    const responseData = response.data as ResponseData<HallData>
                    if (responseData.status === 200) {
                        deskSelection.value = responseData.data!.gameTableList
                        store.commit(
                            AppMutation.SET_USER_GUNNER_BIND,
                            responseData.data?.bindGunner || false
                        )

                        const p = responseData.data?.limitList || []

                        p.forEach((i) => {
                            i.stringValue = `${i.downLimit}-${i.upLimit}`
                        })

                        store.commit(AppMutation.SET_LIMITS, p)

                        let _lobyNoticeList = responseData.data!.noticeList
                        let _lobyNotice = ''

                        _lobyNoticeList.forEach((_notice: any, index) => {
                            if (index === 0) _lobyNotice = _notice.content
                            else
                                _lobyNotice = `${_lobyNotice},   ${_notice.content}`
                        })

                        lobyNotice.value = _lobyNotice
                        noticeKey.value = new Date().getTime()

                        const gameTypes = responseData.data!.gameTypeList

                        store.commit(AppMutation.SET_GAME_TYPES, gameTypes)

                        const memberInfo = responseData.data?.member
                        if (memberInfo) {
                            store.commit(
                                AppMutation.SET_MEMBER_INFO,
                                memberInfo
                            )

                            store.commit(
                                AppMutation.SET_COIN,
                                memberInfo.amount
                            )

                            const limit = limitOptionArray.value.find(
                                (x) => x.id === memberInfo.limitId
                            )

                            if (limit) {
                                limitOption.value =
                                    limitOptionArray.value.indexOf(limit)
                            }
                        }
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch((e) => {
                    if (!_forceReload) store.commit(AppMutation.HIDE_LOADING)
                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.SMALL_TOAST,
                            '当前网络不佳，请重新尝试'
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadData(true)
                        }, 1500)
                    }
                })
        }

        const loadMultiDesk = async (_tables: string) => {
            if (_tables === '') return

            const _params: any = {
                token: token.value,
                memberId: memberId.value,
                tableIds: _tables,
                gameHallType: 0
            }

            // console.log(_params)
            getMultiTableDetail(_params)
                .then((response) => {
                    loadDataFailedCounter = 0
                    const responseData = response.data as ResponseData<HallData>
                    if (responseData.status === 200) {
                        const _data = responseData.data?.gameTableList
                        if (_data) desks.value = _data
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description
                        )
                        store.commit(AppMutation.CLEAR_ALL)
                        emitter.emit(EventBuss.SOCKET_CLOSE)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch(() => {
                    loadDataFailedCounter++
                    if (loadDataFailedCounter > 3) {
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            '当前网络不佳，请重新尝试'
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    } else {
                        // reload after 1 second
                        setTimeout(() => {
                            loadMultiDesk(_tables)
                        }, 1500)
                    }
                })
        }

        const toggleDisplayOptionView = () => {
            if (displayOption.value === 2) {
                displayOption.value = 0
            } else {
                displayOption.value++
            }
        }

        const backHistory = () => {
            history.go(-1)
        }

        const hadleOnCommisionChange = (_deskId: number) => {
            // first find on the list
            let _desk = desks.value.find((x) => x.id === _deskId)
            let index = -1
            let copyDesk
            if (_desk) {
                index = desks.value.indexOf(_desk)
                if (index !== -1) {
                    copyDesk = { ..._desk }
                    copyDesk.expand.memberCommissionStatus =
                        copyDesk.expand.memberCommissionStatus === 1 ? -1 : 1
                    desks.value[index] = copyDesk
                }
                return
            }

            _desk = deskSelection.value.find((x) => x.id === _deskId)

            if (_desk) {
                index = deskSelection.value.indexOf(_desk)
                if (index !== -1) {
                    copyDesk = { ..._desk }
                    copyDesk.expand.memberCommissionStatus =
                        copyDesk.expand.memberCommissionStatus === 1 ? -1 : 1
                    deskSelection.value[index] = copyDesk
                }
            }
        }

        const handleAddAll = () => {
            if (deskSelection.value.length > 0) {
                let itemCountPushed = 0
                deskSelection.value.forEach((d, index) => {
                    const _desk = desks.value.find((i) => i.id === d.id)
                    if (!_desk) {
                        setTimeout(() => {
                            desks.value.push(d)
                            itemCountPushed += 1
                            const _selectedTables = [...multiTables.value]
                            _selectedTables.push(d.id)
                            store.commit(
                                AppMutation.SET_MULTITABLES,
                                _selectedTables
                            )
                        }, index * 150)
                    }
                })

                if (itemCountPushed > 0) {
                    // reload data
                    loadMultiDesk(getMultitableIds())
                }
            }
        }

        const handleUnlokItem = (_data: TableData) => {
            const _tempDesk = desks.value.find((x) => x.id === _data.id)

            if (!_tempDesk) {
                desks.value.push(_data)
                const _selectedTables = [...multiTables.value]
                _selectedTables.push(_data.id)
                store.commit(AppMutation.SET_MULTITABLES, _selectedTables)
                loadMultiDesk(getMultitableIds())

                if (desks.value.length === 1) {
                    tableVideo.value = _data.id
                    store.commit(AppMutation.SET_MULTI_TABLE_VIDEO, _data.id)
                }
            }
        }

        const enterGame = (_table: TableData) => {
            if (_table.gameTypeNo !== 'G001') {
                if (_table.gameHallType === 1) {
                    router.push({
                        name: ROUTES.DEFAULT_GAME,
                        query: { desk: _table.id, hall: 0 }
                    })
                } else {
                    router.push({
                        name: ROUTES.CLASSIC_GAME,
                        query: { desk: _table.id, hall: 0 }
                    })
                }
            } else {
                router.push({
                    name: ROUTES.NIU_NIU,
                    query: { desk: _table.id }
                })
            }
        }

        const showSettingDialog = () => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'setting'
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const requestFullScreen = () => {
            emitter.emit(EventBuss.REQUEST_FULL_SCREEN)
        }

        const setHall = (num: number) => {
            if (num === 5) router.push({ name: ROUTES.MULTI })
            else router.push({ name: ROUTES.LOBY, query: { hall: num } })
        }

        const handleLockItem = (_data: TableData) => {
            const _tempDesk = desks.value.find((x) => x.id === _data.id)

            if (_tempDesk) {
                const i = desks.value.indexOf(_tempDesk)
                if (i > -1) {
                    desks.value.splice(i, 1)
                    const _selectedTables = [...multiTables.value]
                    _selectedTables.splice(
                        multiTables.value.indexOf(_tempDesk.id),
                        1
                    )
                    store.commit(AppMutation.SET_MULTITABLES, _selectedTables)

                    const deskFromSelection = deskSelection.value.find(
                        (x) => x.id === _tempDesk.id
                    )
                    if (deskFromSelection) {
                        const _index =
                            deskSelection.value.indexOf(deskFromSelection)
                        if (_index !== -1) {
                            const copyData = { ..._tempDesk }
                            // to be fixed
                            deskSelection.value[_index] = _tempDesk
                        }
                    }
                }

                if (desks.value.length === 0) {
                    tableVideo.value = -1
                    store.commit(AppMutation.SET_MULTI_TABLE_VIDEO, -1)
                }
            }
        }

        const getMultitableIds = () => {
            let tableIds = ''

            multiTables.value.forEach((_id, _index) => {
                if (_id !== 0) tableIds += `,${_id}`
            })

            if (tableIds.length > 0) tableIds = tableIds.substring(1)
            return tableIds
        }

        const stopBetMultitable = (id: number) => {
            const _desk = desks.value.find((_d) => _d.id === id)

            if (_desk) {
                _desk.expand.daoJiShiBetTime = -1
                _desk.status = 2
            }
        }

        const showDilog = (name: DialogBoxName) => {
            const _evt: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: name
            }

            emitter.emit(EventBuss.DIALOG, _evt)
        }

        const toggleSelectTable = () => {
            //
        }

        //#endregion

        //#endregion Api Call
        const switchUserLimit = (limit: LimitOption) => {
            const params = {
                memberId: memberId.value,
                limitId: limit.id,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            switchLimit(params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    const responseData = response.data as ResponseData<any>

                    if (responseData.status === 200) {
                        const limitId =
                            limitOptionArray.value[limitOption.value]
                        store.commit(AppMutation.SET_LIMIT, limitId.id)
                    } else if (
                        responseData.status === 100 ||
                        responseData.status === 301
                    ) {
                        // token is expred
                        emitter.emit(
                            EventBuss.TOAST_MESSAGE,
                            responseData.description!
                        )

                        store.commit(AppMutation.CLEAR_ALL)
                        router.push({ name: ROUTES.LOGIN })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        '当前网络不佳，请重新尝试'
                    )
                })
        }

        //#endregion

        const filteredDesk = computed(() => {
            return deskSelection.value.filter((x) => {
                for (let _index = 0; _index < desks.value.length; _index++) {
                    if (x.id === desks.value[_index].id) {
                        return false
                    }
                }

                if (selectedGameType.value === 1 && x.gameTypeNo === 'G002') {
                    return true
                } else if (
                    selectedGameType.value === 2 &&
                    x.gameTypeNo === 'G003'
                ) {
                    return true
                } else if (selectedGameType.value === 0) {
                    return true
                }

                return false
            })
        })

        const displayOptionLabel = computed(() => {
            const displayValue = displayOption.value
            if (displayValue === 0) return t('common.allroad')
            else if (displayValue === 1) return t('common.mainroad')
            else return t('common.statistics')
        })

        const isfullScreen = computed(() => store.getters['isfullScreen'])
        const coin = computed(() => store.getters['coin'])
        const user = computed(() => store.getters['user'])
        const limitOptionArray = computed(
            (): LimitOption[] => store.getters['limits']
        )
        const notice = computed(() => store.getters['notice'])
        const token = computed(() => store.getters['token'])
        const memberId = computed(() => store.getters['memberid'])
        const multiTables = computed(
            (): number[] => store.getters['multiTables']
        )
        const avatar = computed(() => store.getters['avatar'])
        const deviceOrientation = computed(
            (): 'landscape' | 'portrait' => store.getters['deviceOrientation']
        )

        const multiTableVideo = computed(
            (): number => store.getters['multiTableVideo']
        )

        const hasAnchorTables = computed(
            (): boolean => store.getters['hasAnchorTables']
        )

        const hasClassicTables = computed(
            (): boolean => store.getters['hasClassicTables']
        )

        watch(
            () => limitOption.value,
            (newValue, oldValue) => {
                if (oldValue !== -1)
                    switchUserLimit(limitOptionArray.value[newValue])
            }
        )

        watch(
            () => deviceOrientation.value,
            (v) => {
                // load multitables
                loadMultiDesk(getMultitableIds())

                // load data
                loadData()
            }
        )

        return {
            hasAnchorTables,
            hasClassicTables,
            deviceOrientation,
            displayOption,
            noticeKey,
            displayOptionLabel,
            listKey,
            showMoreInfo,
            isfullScreen,
            desks,
            coin,
            user,
            limitOption,
            limitOptionArray,
            notice,
            gameTypesOptions,
            selectedGameType,
            deskSelection,
            filteredDesk,
            chip,
            avatar,
            showSelectTable,
            lobyNotice,
            handleLoadItems,
            hadleOnCommisionChange,
            toggleSelectTable,
            handleUnlokItem,
            requestFullScreen,
            toggleDisplayOptionView,
            showSettingDialog,
            enterGame,
            setHall,
            backHistory,
            handleLockItem,
            handleAddAll,
            loadData,
            stopBetMultitable,
            showDilog,
            reloadData,
            setTableVideo,
            t,
            showAllMenu,
            tableVideo
        }
    }
})
