
import {
    ChipItem,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    TableData,
    watch
} from 'vue'
import RoadMap from '@/components/roadmap/index.vue'
import { RoadMapData } from './roadmap/datamodel/RoadMapData'
import { parseRoadMap } from './roadmap/utils'
import { useCountdown } from '@/composables/useCountDown'
import { EventBuss, ROADMAP_RECOMADATION } from '@/types/global'
import { AppMutation } from '@/store/types'
import { setCommissionTable } from '@/api'
import { useStore } from '@/store'
import DPrediction from '@/components/d-prediction.vue'
import { RoadMapSummaryData } from './roadmap/datamodel/types'
import VideoLoader from '@/components/video-loader.vue'
import BetGroup from '@/components/multi-table/portrait/bet-group.vue'
import { useI18n } from 'vue-i18n'
import CardResult from '@/components/multi-table/portrait/card-result.vue'

export default defineComponent({
    emits: ['lock', 'on-commision-change', 'play-video'],
    props: {
        display: {
            required: false,
            type: Number as PropType<number>,
            default: -1
        },
        desk: {
            type: Object as PropType<TableData>,
            required: true
        },
        chip: {
            type: Object as PropType<ChipItem>,
            require: false
        },
        activeVideoDeskId: {
            type: Number as PropType<number>,
            default: () => -1
        }
    },
    components: {
        RoadMap,
        DPrediction,
        'video-loader': VideoLoader,
        'bet-group': BetGroup,
        'card-result': CardResult
    },
    setup(props, { emit }) {
        const store = useStore()
        const emitter = inject('emitter') as Emitter
        const { t } = useI18n()
        const mapdata = ref<RoadMapData>(parseRoadMap([]))
        const isCommissionOn = ref(true)
        const nextRedRoadMapSummaryData = ref<RoadMapSummaryData>({})
        const nextBlueRoadMapSummaryData = ref<RoadMapSummaryData>({})

        const roadmapview1 = ref()
        const refBetGroup = ref()

        const showVideo = ref(false)
        const isVideoPreviewOn = ref(false)
        let videoPlayerCollection: Array<any> = new Array<any>()
        let nodePlayer!: any
        const isLoadingVideo = ref<boolean>(true)

        const hasTempBet = ref(false)

        let showCardTimeOut: number | boolean | any = false
        const showCardResult = ref(false)

        const goodWayLabel = ref('')
        const blinkTimeOut = ref<number | boolean | any>(false)

        const onCountdownStop = () => {
            if (!showVideo.value && !isVideoPreviewOn.value) {
                if (props.activeVideoDeskId !== props.desk.id) {
                    isVideoPreviewOn.value = false
                } else {
                    isVideoPreviewOn.value = true
                    // if(isVideoPreviewOn.value&& isVd)
                    setTimeout(() => {
                        initVideoPlayer()
                    }, 250)
                }
            }

            cancelTempbet()
        }

        const { currentCount, startCountDown, stopCountDown } = useCountdown(
            false,
            onCountdownStop
        )

        //#region Vue Helpers

        onMounted(() => {
            emitter.on(EventBuss.MULTI_CANCEL_GAME, handleCancelGame)
            emitter.on(EventBuss.MULTI_END_GAME, handleEndGame)
        })
        onBeforeUnmount(() => {
            // stop showing card result
            if (typeof showCardTimeOut === 'number') {
                clearTimeout(showCardTimeOut)
                showCardTimeOut = false
            }

            emitter.off(EventBuss.MULTI_CANCEL_GAME, handleCancelGame)
            emitter.off(EventBuss.MULTI_END_GAME, handleEndGame)

            stopCountDown()
            disposeVideoPlayer()
            stopGoodWayBlink()
        })

        //#endregion

        //#region Video Player Helpers
        const initVideoPlayer = () => {
            if (props.activeVideoDeskId !== props.desk.id) {
                showVideo.value = false
                return
            }

            isLoadingVideo.value = true
            disposeVideoPlayer()

            const videoPaths = [process.env.VIDEO1, process.env.VIDEO2]

            let isVideoConnected = false

            const videoUrl = props.desk.cmccLine || ''

            videoPaths.forEach((vPath) => {
                const _videoUrl =
                    vPath +
                    '/' +
                    videoUrl.replace(/(http:|)(^|\/\/)(.*?\/)/g, '')

                // eslint-disable-next-line no-undef
                let _player: NodePlayer | any = new NodePlayer()

                _player.setVolume(0.0)

                _player.setView(`video2_${props.desk.id}`)
                _player.setScaleMode(2)

                _player.setBufferTime(1000)

                _player.on('start', () => {
                    if (!isVideoConnected) {
                        isVideoConnected = true
                        nodePlayer = _player
                        nodePlayer.clearView()

                        nodePlayer.setView(`video1_${props.desk.id}`)
                        nodePlayer.on(
                            'buffer',
                            (evt: 'empty' | 'buffering' | 'full') => {
                                if (evt === 'empty' || evt === 'buffering') {
                                    isLoadingVideo.value = true
                                } else if (evt === 'full') {
                                    isLoadingVideo.value = false
                                }
                            }
                        )
                        nodePlayer.on('error', (e: any) => {
                            isLoadingVideo.value = true
                            setTimeout(() => {
                                nodePlayer.clearView()
                                nodePlayer.stop()
                                initVideoPlayer()
                            }, 1000)
                        })
                    } else {
                        // console.log('stop')
                        _player.clearView()
                        _player.stop()
                    }
                })
                _player.on('error', () => {
                    //
                })
                videoPlayerCollection.push(_player)
                _player.start(_videoUrl)
            })

            // isLoadingVideo.value = true
            // disposeVideoPlayer()

            // let isVideoConnected = false

            // const videoUrl = props.desk.cmccLine || ''

            // // eslint-disable-next-line no-undef
            // nodePlayer = new NodePlayer()
            // nodePlayer.setVolume(0)
            // nodePlayer.setView(`video_${props.desk.id}`)
            // nodePlayer.setScaleMode(2)

            // nodePlayer.setBufferTime(1000)

            // nodePlayer.on('buffer', (evt: 'empty' | 'buffering' | 'full') => {
            //     if (evt === 'empty' || evt === 'buffering') {
            //         isLoadingVideo.value = true
            //     } else if (evt === 'full') {
            //         isLoadingVideo.value = false
            //     }
            // })

            // nodePlayer.on('error', () => {
            //     isLoadingVideo.value = true
            //     setTimeout(() => {
            //         nodePlayer.clearView()
            //         nodePlayer.stop()
            //         initVideoPlayer()
            //     }, 1000)
            // })

            // nodePlayer.start(videoUrl)
        }
        const disposeVideoPlayer = () => {
            if (nodePlayer) {
                nodePlayer.stop()
                nodePlayer.clearView()
                nodePlayer = undefined
                isLoadingVideo.value = true
            }

            videoPlayerCollection.forEach((_player) => {
                if (_player) {
                    _player.clearView()
                    _player.stop()
                }
            })

            videoPlayerCollection = []
        }
        //#endregion

        //#region Private helpers

        const handleEndGame = (_deskId: number) => {
            handleCancelGame(_deskId)

            if (props.desk && props.desk.id === _deskId) {
                if (typeof showCardTimeOut === 'number') {
                    clearTimeout(showCardTimeOut)
                    showCardTimeOut = false
                }

                // show card result

                showCardResult.value = true

                showCardTimeOut = setTimeout(() => {
                    showCardResult.value = false
                    showCardTimeOut = false
                    isVideoPreviewOn.value = false
                }, 6000)
            }
        }

        const handleCancelGame = (_deskId: number) => {
            if (props.desk && props.desk.id === _deskId && refBetGroup.value) {
                refBetGroup.value.endGame()
            }
        }
        const toggleTableCommission = () => {
            if (props.desk && props.desk.expand.betFormList) {
                let totalBet = 0
                props.desk.expand.betFormList.forEach((i) => {
                    if (i.memberId === memberId.value) {
                        totalBet += i.amount
                    }
                })

                if (totalBet > 0) return
            }

            const _params = {
                memberId: memberId.value,
                tableId: props.desk.id,
                status: isCommissionOn.value ? -1 : 1,
                token: token.value
            }

            store.commit(AppMutation.SHOW_LOADING)

            setCommissionTable(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: response.data.description,
                            id: props.desk.id
                        })
                    } else if (response.data.status === 200) {
                        // isCommissionOn.value = !isCommissionOn.value
                        emit('on-commision-change', props.desk.id)
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前网络不佳，请重新尝试',
                        id: props.desk.id
                    })
                })

            //
        }
        const toggleVideo = () => {
            if (!showVideo.value) {
                showVideo.value = true
                emit('play-video', props.desk.id)
                if (!isVideoPreviewOn.value) {
                    setTimeout(() => {
                        initVideoPlayer()
                    }, 200)
                } else {
                    showVideo.value = false
                    isVideoPreviewOn.value = false
                    disposeVideoPlayer()
                }
            } else {
                // stop video
                disposeVideoPlayer()
                showVideo.value = false
                isVideoPreviewOn.value = false
            }
        }
        const lock = () => {
            const copyData = { ...props.desk }
            copyData.expand.daoJiShiBetTime = currentCount.value
            if (currentCount.value <= 0) copyData.status = 2
            emit('lock', copyData)
        }
        const onRoadMapDataParsed = (
            a: RoadMapData,
            b: RoadMapData,
            c: RoadMapData
        ) => {
            mapdata.value = a
            nextRedRoadMapSummaryData.value = { data: b }
            nextBlueRoadMapSummaryData.value = { data: c }

            if (mapdata.value.goodway.length > 0) {
                // goodWayLabel.value = ROADMAP_RECOMADATION.toLabels(
                //     mapdata.value.goodway[0].id,
                //     gameType.value
                // )

                goodWayLabel.value = t(
                    `goodroad.${ROADMAP_RECOMADATION.toLabels(
                        mapdata.value.goodway[0].id,
                        gameType.value
                    )}`
                )

                blinkTimeOut.value = setTimeout(() => {
                    if (typeof blinkTimeOut.value === 'number') {
                        stopGoodWayBlink()
                    }
                }, 3000)
            } else {
                goodWayLabel.value = ''
            }
        }

        const stopGoodWayBlink = () => {
            if (typeof blinkTimeOut.value === 'number') {
                clearTimeout(blinkTimeOut.value)
                blinkTimeOut.value = false
            }
        }

        const predict = (type: number) => {
            if (roadmapview1.value) {
                roadmapview1.value.Predict(type)
            }
        }

        const onPlaceTempBetOrClear = (b: boolean) => {
            hasTempBet.value = b
        }

        const confirmTempBet = () => {
            if (refBetGroup.value && hasTempBet.value) {
                refBetGroup.value.confirmTempBet()
            }
        }

        const cancelTempbet = () => {
            if (refBetGroup.value) refBetGroup.value.resetTempBet()
        }
        //#endregion

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })

        const redCount = computed(() => {
            if (mapdata.value) return mapdata.value.redCount
            return 0
        })

        const blueCount = computed(() => {
            if (mapdata.value) return mapdata.value.blueCount
            return 0
        })

        const greenCount = computed(() => {
            if (mapdata.value) return mapdata.value.tieCount
            return 0
        })

        const redPairCount = computed(() => {
            if (mapdata.value) return mapdata.value.redPairCount
            return 0
        })
        const memberId = computed(() => store.getters['memberid'])
        const token = computed(() => store.getters['token'])

        const bluePairCount = computed(() => {
            if (mapdata.value) return mapdata.value.bluePairCount
            return 0
        })

        const blueStat = computed(() => {
            if (mapdata.value.round === 0) return '0%'
            else
                return (
                    Math.floor((blueCount.value / mapdata.value.round) * 100) +
                    '%'
                )
        })

        const redStat = computed(() => {
            if (mapdata.value.round === 0) return '0%'
            return (
                Math.floor((redCount.value / mapdata.value.round) * 100) + '%'
            )
        })

        const greenStat = computed(() => {
            if (mapdata.value.round === 0) return '0%'
            return (
                Math.floor((greenCount.value / mapdata.value.round) * 100) + '%'
            )
        })

        const roundCount = computed(() => mapdata.value.round)

        const gameType = computed(() => {
            if (props.desk) return props.desk.gameTypeNo === 'G002' ? 1 : 2
            return -1
        })

        const tableName = computed(() => {
            const { tableNo, gameHallType } = props.desk

            let _tableName = ''
            if (props.desk.gameTypeNo.toUpperCase() === 'G002')
                _tableName = `${t('desklist.baccarattable', {
                    tablenumber: tableNo
                })}`
            else
                _tableName = `${t('desklist.dragontigertable', {
                    tablenumber: tableNo
                })}`

            if (gameHallType === 2)
                _tableName = t('desklist.classic') + _tableName

            return _tableName
        })

        const redLabel = computed(() =>
            gameType.value === 1 ? t('roadmap.banker') : t('roadmap.dragon')
        )
        const blueLabel = computed(() =>
            gameType.value === 1 ? t('roadmap.player') : t('roadmap.tiger')
        )
        const coin = computed((): number => store.getters['coin'])

        watch(
            () => props.desk,
            (_v) => {
                if (_v.expand.daoJiShiBetTime > 0) {
                    startCountDown(_v.expand.daoJiShiBetTime)
                    if (!showVideo.value && isVideoPreviewOn.value) {
                        disposeVideoPlayer()
                        isVideoPreviewOn.value = false
                    }
                } else {
                    stopCountDown()
                }

                if (props.desk.commissionStatus === -1) {
                    isCommissionOn.value = false
                } else {
                    isCommissionOn.value =
                        props.desk &&
                        props.desk.expand.memberCommissionStatus === 1
                }
            },
            { immediate: true }
        )

        watch(
            () => props.activeVideoDeskId,
            (v) => {
                if (v !== props.desk.id) {
                    showVideo.value = false
                    isVideoPreviewOn.value = false
                    disposeVideoPlayer()
                }
            }
        )

        return {
            timerColor,
            currentCount,
            redCount,
            blueCount,
            greenCount,
            redPairCount,
            bluePairCount,
            blueStat,
            redStat,
            greenStat,
            roundCount,
            isCommissionOn,
            gameType,
            nextRedRoadMapSummaryData,
            nextBlueRoadMapSummaryData,
            roadmapview1,
            showVideo,
            isVideoPreviewOn,
            isLoadingVideo,
            hasTempBet,
            refBetGroup,
            tableName,
            showCardResult,
            redLabel,
            blueLabel,
            goodWayLabel,
            blinkTimeOut,

            lock,
            onRoadMapDataParsed,
            toggleTableCommission,
            predict,
            toggleVideo,
            confirmTempBet,
            cancelTempbet,
            onPlaceTempBetOrClear,
            t
        }
    }
})
