
import { PropType, TableData, computed, defineComponent, ref, watch } from 'vue'
import { useCountdown } from '@/composables/useCountDown'
import { useI18n } from 'vue-i18n'
import { Nui } from '@/types/Nui'

export default defineComponent({
    emits: ['unlock'],
    props: {
        mData: {
            type: Object as PropType<TableData>,
            required: true
        }
    },
    setup(props, { emit }) {
        const { t } = useI18n()
        const { currentCount, startCountDown, stopCountDown } = useCountdown()
        const nuiList = ref<Nui[]>([])
        const unlock = () => {
            const copyData = { ...props.mData }
            copyData.expand.daoJiShiBetTime = currentCount.value
            if (currentCount.value <= 0) copyData.status = 2
            emit('unlock', copyData)
        }

        const tableName = computed(() => {
            const { tableNo } = props.mData
            // const name = props.mData.gameTypeNo.toUpperCase()

            // if (name === 'G002') return `${t('baccarattable')}${tableNo}`
            // else if (name === 'G003')
            //     return `${t('dragontigertable')}${tableNo}`
            // else return `牛牛 ${tableNo}`
            return t('desklist.niuniutable', { tablenumber: tableNo })
        })

        const timerColor = computed(() => {
            if (currentCount.value === -1) {
                return 'wait'
            } else if (currentCount.value <= 10) {
                return 'red'
            } else {
                return ''
            }
        })

        watch(
            () => props.mData,
            (_v) => {
                if (_v.expand.daoJiShiBetTime > 0) {
                    startCountDown(_v.expand.daoJiShiBetTime)
                } else {
                    stopCountDown()
                }

                if (_v && _v.expand.resultList.length > 0) {
                    nuiList.value = Nui.ParseList(
                        _v.expand.resultList as Array<string>,
                        7
                    )
                }
            },
            { immediate: true }
        )

        return {
            currentCount,
            tableName,
            timerColor,
            nuiList,
            unlock,
            t
        }
    }
})
