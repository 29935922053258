
import { useStore } from '@/store'
import { BetType, EventBuss } from '@/types/global'
import {
    ChipItem,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    TableData,
    watch
} from 'vue'
import { abbreviateNumber } from '@/utils'
import audioPlayer, { SOUNDKEY } from '@/utils/sounds'
import { AppMutation } from '@/store/types'
import { saveBet } from '@/api'
import {
    IconTiger,
    IconDragon,
    IconTie,
    SvgPlayerpair,
    SvgTie2,
    SvgBankerpair,
    SvgPlayer,
    SvgBanker,
    SvgDolar
} from '@/components/icons'
import BetBox from '@/components/d-bet-box.vue'

export default defineComponent({
    props: {
        gameType: {
            required: false,
            type: Number as PropType<number>,
            default: -1
        },
        mData: {
            type: Object as PropType<TableData>,
            required: true,
            default: undefined
        },
        chip: {
            type: Object as PropType<ChipItem>,
            required: false
        },
        isCommisionTable: {
            type: Boolean as PropType<boolean>,
            required: false,
            default: () => true
        }
    },
    components: {
        'svg-tiger': IconTiger,
        'svg-tie-0': IconTie,
        'svg-dragon': IconDragon,
        'svg-player-pair': SvgPlayerpair,
        'svg-tie-2': SvgTie2,
        'svg-banker-pair': SvgBankerpair,
        'svg-player': SvgPlayer,
        'svg-banker': SvgBanker,
        'bet-box': BetBox,
        SvgDolar
    },
    setup(props, { emit }) {
        const store = useStore()
        const emitter = inject('emitter') as Emitter

        const pBet = ref<number>(0)
        const bBet = ref<number>(0)
        const ppBet = ref<number>(0)
        const bpBet = ref<number>(0)
        const tBet = ref<number>(0)
        const drBet = ref<number>(0)
        const tgBet = ref<number>(0)
        const tempPBet = ref<number>(0)
        const tempBBet = ref<number>(0)
        const tempPpBet = ref<number>(0)
        const tempBpBet = ref<number>(0)
        const tempTBet = ref<number>(0)
        const tempDrBet = ref<number>(0)
        const tempTgBet = ref<number>(0)

        // Baccarat Box

        const bankerBox = ref<any>()
        const playerBox = ref<any>()
        const tieBox = ref<any>()
        const bankerPBox = ref<any>()
        const playerPBox = ref<any>()
        // Dragon tiger box

        const dragonBox = ref<any>()
        const tigerBox = ref<any>()
        const tieTGBox = ref<any>()

        const toastMessage = ref<
            { id: number | boolean | any; message: string }[]
        >([])

        //#region Vue Helpers

        onMounted(() => {
            emitter.on(EventBuss.SMALL_TOAST, handleToastMessage)
            // console.log(props.gameType)

            if (props.mData) {
                resetBetUI()
            }
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.SMALL_TOAST, handleToastMessage)
        })

        //#endregion

        //#region Private Helpers
        const handleToastMessage = (message: { msg: string; id: number }) => {
            if (props.mData && props.mData.id === message.id) {
                const _t = {
                    id: setTimeout(() => {
                        const _tt = toastMessage.value.shift()
                        if (_tt && typeof _tt.id === 'number') {
                            clearTimeout(_tt.id)
                        }
                    }, 1500),
                    message: message.msg
                }
                toastMessage.value.push(_t)
            }
        }

        const placeBet = (_betType: number) => {
            // check if user can bet
            if (!props.mData) return

            const { mData } = props

            // check if its allowed to bet
            if (mData.status !== 1) {
                // countdown timer is done, no betting allowed
                emitter.emit(EventBuss.SMALL_TOAST, {
                    msg: '当前不能下注',
                    id: props.mData?.id || 0
                })
                return
            }

            const _amount = props.chip?.value || 0

            if (_amount > 0) {
                // check total temp bet is sufficient

                if (!isBetIsSufficient(_amount)) {
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: ' 余额不足，投注失败！',
                        id: props.mData?.id || 0
                    })

                    return
                }
                switch (_betType) {
                    case BetType.BANKER:
                        tempBBet.value += _amount
                        break
                    case BetType.DRAGON:
                        tempDrBet.value += _amount
                        break
                    case BetType.PLAYER:
                        tempPBet.value += _amount
                        break
                    case BetType.TIGER:
                        tempTgBet.value += _amount
                        break
                    case BetType.BC_TIE:
                    case BetType.DT_TIE:
                        tempTBet.value += _amount
                        break
                    case BetType.PLAYER_PAIR:
                        tempPpBet.value += _amount
                        break
                    case BetType.BANKER_PAIR:
                        tempBpBet.value += _amount
                        break
                }

                emitter.emit(EventBuss.PLACE_CHIP, {
                    t: _betType,
                    amt: _amount,
                    type: 'temp',
                    deskId: props.mData.id
                })
            }

            callUpdateCallback()
        }

        const isBetIsSufficient = (amount: number) => {
            const total =
                tempPBet.value +
                tempBBet.value +
                tempPpBet.value +
                tempBpBet.value +
                tempTBet.value +
                tempDrBet.value +
                tempTgBet.value +
                amount

            return coin.value >= total
        }

        const resetTempBet = () => {
            tempPBet.value = 0
            tempBBet.value = 0
            tempPpBet.value = 0
            tempBpBet.value = 0
            tempTBet.value = 0
            tempDrBet.value = 0
            tempTgBet.value = 0

            // bac
            if (bankerBox.value) bankerBox.value.removeTempUIBet()
            if (playerBox.value) playerBox.value.removeTempUIBet()
            if (tieBox.value) tieBox.value.removeTempUIBet()
            if (bankerPBox.value) bankerPBox.value.removeTempUIBet()
            if (playerPBox.value) playerPBox.value.removeTempUIBet()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.removeTempUIBet()
            if (tigerBox.value) tigerBox.value.removeTempUIBet()
            if (tieTGBox.value) tieTGBox.value.removeTempUIBet()

            callUpdateCallback()
        }

        const resetBetUI = () => {
            if (props.gameType === 1) {
                // bac
                if (bankerBox.value && bBet.value > 0)
                    bankerBox.value.handleOnPlaceChip({
                        t: BetType.BANKER,
                        amt: bBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                if (playerBox.value && pBet.value > 0) {
                    playerBox.value.handleOnPlaceChip({
                        t: BetType.PLAYER,
                        amt: pBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                }

                if (tieBox.value && tBet.value > 0) {
                    tieBox.value.handleOnPlaceChip({
                        t: BetType.BC_TIE,
                        amt: tBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                }

                if (bankerPBox.value && bpBet.value > 0) {
                    bankerPBox.value.handleOnPlaceChip({
                        t: BetType.BANKER_PAIR,
                        amt: bpBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                }

                if (playerPBox.value && ppBet.value > 0) {
                    playerPBox.value.handleOnPlaceChip({
                        t: BetType.PLAYER_PAIR,
                        amt: ppBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                }
            } else {
                if (tigerBox.value && tgBet.value > 0) {
                    tigerBox.value.handleOnPlaceChip({
                        t: BetType.TIGER,
                        amt: tgBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                }

                if (dragonBox.value && drBet.value > 0) {
                    dragonBox.value.handleOnPlaceChip({
                        t: BetType.DRAGON,
                        amt: drBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                }

                if (tieTGBox.value && tBet.value > 0) {
                    dragonBox.value.handleOnPlaceChip({
                        t: BetType.DT_TIE,
                        amt: tBet.value,
                        type: 'confirmed',
                        deskId: props.mData?.id || 0
                    })
                }
            }
        }
        const applyTempBet = () => {
            tempPBet.value = 0
            tempBBet.value = 0
            tempPpBet.value = 0
            tempBpBet.value = 0
            tempTBet.value = 0
            tempDrBet.value = 0
            tempTgBet.value = 0

            if (bankerBox.value) bankerBox.value.applyTempUIBet()
            if (playerBox.value) playerBox.value.applyTempUIBet()
            if (tieBox.value) tieBox.value.applyTempUIBet()
            if (bankerPBox.value) bankerPBox.value.applyTempUIBet()
            if (playerPBox.value) playerPBox.value.applyTempUIBet()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.applyTempUIBet()
            if (tigerBox.value) tigerBox.value.applyTempUIBet()
            if (tieTGBox.value) tieTGBox.value.applyTempUIBet()

            callUpdateCallback()
        }

        const callUpdateCallback = () => {
            const hasTempBet =
                tempPBet.value +
                    tempBBet.value +
                    tempPpBet.value +
                    tempBpBet.value +
                    tempTBet.value +
                    tempDrBet.value +
                    tempTgBet.value >
                0

            emit('on-update', hasTempBet)
        }

        const confirmTempBet = () => {
            let _params: any = {}
            if (props.gameType === 1) {
                _params = {
                    memberId: memberId.value,
                    tableId: props.mData!.id,
                    shoeNo: props.mData!.expand.shoeNo,
                    juNo: props.mData!.expand.juNo,
                    gameNo: props.mData!.expand.gameNo,
                    zhuang: tempBBet.value,
                    he: tempTBet.value,
                    xian: tempPBet.value,
                    zhuangDui: tempBpBet.value,
                    xianDui: tempPpBet.value,
                    token: token.value
                }
            } else if (props.gameType === 2) {
                _params = {
                    memberId: memberId.value,
                    tableId: props.mData!.id,
                    shoeNo: props.mData!.expand.shoeNo,
                    juNo: props.mData!.expand.juNo,
                    gameNo: props.mData!.expand.gameNo,
                    zhuang: tempDrBet.value,
                    he: tempTBet.value,
                    xian: tempTgBet.value,
                    token: token.value
                }
            }

            store.commit(AppMutation.SHOW_LOADING)

            saveBet(_params)
                .then((response) => {
                    store.commit(AppMutation.HIDE_LOADING)
                    if (response.data.status === 100) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: response.data.description,
                            id: props.mData?.id || 0
                        })
                        resetTempBet()
                    } else if (response.data.status === 200) {
                        emitter.emit(EventBuss.SMALL_TOAST, {
                            msg: '下注成功',
                            id: props.mData?.id || 0
                        })
                        audioPlayer.Play(SOUNDKEY.BET_SUCCESS)
                        applyTempBet()
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    resetTempBet()
                    emitter.emit(EventBuss.SMALL_TOAST, {
                        msg: '当前网络不佳，请重新尝试',
                        id: props.mData?.id || 0
                    })
                })
        }

        const endGame = () => {
            // bac
            if (bankerBox.value) bankerBox.value.resetBetUI()
            if (playerBox.value) playerBox.value.resetBetUI()
            if (tieBox.value) tieBox.value.resetBetUI()
            if (bankerPBox.value) bankerPBox.value.resetBetUI()
            if (playerPBox.value) playerPBox.value.resetBetUI()

            // dragon tiger
            if (dragonBox.value) dragonBox.value.resetBetUI()
            if (tigerBox.value) tigerBox.value.resetBetUI()
            if (tieTGBox.value) tieTGBox.value.resetBetUI()

            resetTempBet()
        }
        //#endregion

        //#endregion Computed
        const token = computed(() => store.getters['token'])
        const memberId = computed(() => store.getters['memberid'])
        const coin = computed((): number => store.getters['coin'])
        //#endregion
        //#region Watcher

        watch(
            () => props.mData,
            (val, old) => {
                if (val && val.expand.betFormList) {
                    let _pBet = 0
                    let _bBet = 0
                    let _ppBet = 0
                    let _bpBet = 0
                    let _tBet = 0
                    let _drBet = 0
                    let _tgBet = 0

                    const { betFormList } = val.expand
                    betFormList.forEach((i) => {
                        if (i.memberId === memberId.value) {
                            const { playName, amount } = i
                            if (playName === '庄') {
                                _bBet += amount
                            } else if (playName === '庄对') {
                                _bpBet += amount
                            } else if (playName === '闲') {
                                _pBet += amount
                            } else if (playName === '闲对') {
                                _ppBet += amount
                            } else if (playName === '和') {
                                _tBet += amount
                            } else if (playName === '龙') {
                                _drBet += amount
                            } else if (playName === '虎') {
                                _tgBet += amount
                            }
                        }
                    })
                    pBet.value = _pBet
                    bBet.value = _bBet
                    ppBet.value = _ppBet
                    bpBet.value = _bpBet
                    tBet.value = _tBet
                    drBet.value = _drBet
                    tgBet.value = _tgBet
                }
            },
            { immediate: true, deep: true }
        )

        //#region
        return {
            // temp Dragon tiger
            tempTgBet,
            tempDrBet,
            tempTBet,

            // temp Baccarat
            tempPBet,
            tempBBet,
            tempPpBet,
            tempBpBet,
            // Box Baccarat
            bankerBox,
            playerBox,
            tieBox,
            bankerPBox,
            playerPBox,
            // Box Dragon tiger
            dragonBox,
            tigerBox,
            tieTGBox,
            pBet,
            bBet,
            ppBet,
            bpBet,
            tBet,
            drBet,
            tgBet,
            placeBet,
            toastMessage,
            resetTempBet,
            confirmTempBet,
            endGame,
            resetBetUI,
            abbreviateNumber
        }
    }
})
