
import { store } from '@/store'
import { BetType, EventBuss } from '@/types/global'
import {
    ChipItem,
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    StyleValue,
    TableData,
    watch
} from 'vue'
// import { SvgDolar } from '@/components/icons'

export default defineComponent({
    emits: ['place-bet'],
    props: {
        rect: {
            type: Object as PropType<{
                offsetLeft: number
                w: number
                h: number
            }>,
            required: true
        },
        betType: {
            type: Number as PropType<number>,
            required: true
        },
        desk: {
            type: Object as PropType<TableData>,
            required: false
        },
        chipsAttr: {
            type: Object as PropType<{
                width: number
                height: number
                offset: number
            }>,
            default: () => ({ width: 16, height: 16, offset: 5 }),
            required: false
        },
        chip: {
            type: Object as PropType<ChipItem>,
            required: false
        },
        confirmedBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        tempBet: {
            type: Number as PropType<number>,
            required: false,
            default: 0
        },
        display: {
            type: String as PropType<'pc' | 'mobile'>,
            required: false,
            default: () => 'pc'
        }
    },
    components: {
        // 'svg-dolar': SvgDolar
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter

        const chipsUI = ref<
            {
                x: number
                y: number
                amount: number
                class: string
                type: 'temp' | 'confirmed'
            }[]
        >([])
        // region Vue Helpers

        onMounted(() => {
            emitter.on(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        onBeforeUnmount(() => {
            emitter.off(EventBuss.PLACE_CHIP, handleOnPlaceChip)
        })

        // endregion

        // private Helpers
        const placeBet = () => {
            emit('place-bet', props.betType)
        }

        const handleOnPlaceChip = (arg: {
            t: number
            amt: number
            type: 'temp' | 'confirmed'
            deskId: number
        }) => {
            if (props.desk && props.desk.id !== arg.deskId) return

            if (arg.t !== props.betType) return

            if (
                arg.type === 'confirmed' &&
                chipsUI.value.length === 1 &&
                chipsUI.value[0].amount === arg.amt
            ) {
                return
            } else if (arg.type === 'confirmed') {
                chipsUI.value = []
            }

            const position = generatePosition()

            if (props.chip) {
                let chipCLass = `${props.chip.id}`
                if (arg.type === 'confirmed') {
                    const chipDef = chipDefault.value.find(
                        (x) => x.value === arg.amt
                    )
                    if (chipDef) {
                        chipCLass = `${chipDef.id}`
                    } else {
                        chipCLass = `${
                            chipDefault.value[chipDefault.value.length - 1].id
                        }`
                    }
                }
                chipsUI.value.push({
                    x: position.x,
                    y: position.y,
                    amount: arg.amt,
                    class: chipCLass,
                    type: arg.type
                })
            }
        }

        const generatePosition = () => {
            let postion = { x: 0, y: 0 }

            const maxX =
                props.rect.w -
                (props.chipsAttr.width + props.chipsAttr.offset) +
                props.chipsAttr.offset
            const maxY = props.rect.h - props.chipsAttr.height

            const randomX = Math.floor(Math.random() * maxX)

            const randomY = Math.floor(Math.random() * maxY)

            postion.x = Math.floor(randomX)
            postion.y = Math.floor(randomY)

            return postion
        }

        const removeTempUIBet = () => {
            chipsUI.value = chipsUI.value.filter((i) => i.type !== 'temp')
        }

        const applyTempUIBet = () => {
            chipsUI.value = chipsUI.value.map((i) => {
                if (i.type === 'temp') i.type = 'confirmed'
                return i
            })
        }
        const resetBetUI = () => {
            chipsUI.value = []
        }

        const chipStyle = (x: number, y: number): StyleValue => {
            // top:var(--pc${chip.y});left:var(--pc${chip.x});
            const _prefix = props.display === 'pc' ? '--pc' : '--mp'
            const top = `var(${_prefix + Math.floor(y)})`
            const left = `var(${_prefix + Math.floor(x)})`
            return {
                top,
                left
            }
        }

        // endregion

        const chipWrapStyle = computed((): StyleValue => {
            if (props.rect) {
                const { offsetLeft, w } = props.rect
                const _prefix = props.display === 'pc' ? '--pc' : '--mp'
                const left = `var(${_prefix + Math.floor(offsetLeft)})`
                const width = `var(${_prefix + Math.floor(w)})`
                return {
                    // backgroundColor: 'green',
                    left,
                    right: 'auto',
                    width
                }
            }

            return {}
        })

        const amount = computed(() => {
            const confirmedBet = props.confirmedBet ?? 0
            const tempBet = props.tempBet ?? 0
            return confirmedBet + tempBet
        })

        const chipDefault = computed(
            (): ChipItem[] => store.getters['chipDefault']
        )

        watch(
            () => props.confirmedBet,
            (v) => {
                if (v > 0 && chipsUI.value.length === 0) {
                    handleOnPlaceChip({
                        t: props.betType,
                        amt: v,
                        type: 'confirmed',
                        deskId: props.desk?.id || 0
                    })
                }
            },
            { immediate: true }
        )

        return {
            amount,
            chipWrapStyle,
            chipsUI,
            chipStyle,
            placeBet,
            removeTempUIBet,
            resetBetUI,
            applyTempUIBet,
            handleOnPlaceChip
        }
    }
})
