
import { IconStatistics, IconAllRoad, IconBigRoad } from '@/components/icons'
import {
    computed,
    defineComponent,
    onMounted,
    PropType,
    ref,
    TableData
} from 'vue'
import SelectItem from '@/components/multi-table/portrait/select-tables-item.vue'
import MBNuiItem from '@/components/multi-table/portrait/select-tables-item-nui.vue'
import { useI18n } from 'vue-i18n'

export default defineComponent({
    emits: ['close', 'unlock', 'load-items'],
    props: {
        filteredDesk: {
            type: Object as PropType<TableData[]>,
            required: true
        }
    },
    components: {
        IconStatistics,
        IconAllRoad,
        IconBigRoad,
        SelectItem,
        'mb-nui-item': MBNuiItem
    },
    setup(props, { emit }) {
        const gameOptions = ref<number[]>([0, 1, 2])
        const displayOption = ref(0)
        const { t } = useI18n()

        //#region Private Helpers

        const selectGameOption = (option: number) => {
            const _index = gameOptions.value.indexOf(option)

            if (_index !== -1) gameOptions.value.splice(_index, 1)
            else gameOptions.value.push(option)

            emit('load-items', gameOptions.value)
        }

        const loadData = (_forceReload = false) => {
            emit('load-items', gameOptions.value)
        }

        onMounted(() => {
            emit('load-items', gameOptions.value)
        })

        const toggleDisplayOptionView = () => {
            if (displayOption.value === 2) {
                displayOption.value = 0
            } else {
                displayOption.value++
            }
        }
        //#endregion

        //#region  Computed Values
        const optionBaccaratChecked = computed(() => {
            return gameOptions.value.indexOf(0) !== -1
        })

        const optionDragonTigerChecked = computed(() => {
            return gameOptions.value.indexOf(1) !== -1
        })

        const optionNuiNuiChecked = computed(() => {
            return gameOptions.value.indexOf(2) !== -1
        })

        const displayOptionLabel = computed(() => {
            const displayValue = displayOption.value
            if (displayValue === 0) return t('common.allroad')
            else if (displayValue === 1) return t('common.mainroad')
            else return t('common.statistics')
        })

        const desksList = computed((): TableData[] => {
            const baccarat = gameOptions.value.indexOf(0) !== -1
            const dtiger = gameOptions.value.indexOf(1) !== -1
            const nui = gameOptions.value.indexOf(2) !== -1

            if (baccarat && dtiger && nui) return props.filteredDesk
            else if (baccarat && dtiger && !nui) {
                return props.filteredDesk.filter(
                    (_x) => _x.gameTypeNo === 'G002' || _x.gameTypeNo === 'G003'
                )
            } else if (baccarat && !dtiger && !nui) {
                return props.filteredDesk.filter(
                    (_x) => _x.gameTypeNo === 'G002'
                )
            } else if (!baccarat && !dtiger && nui) {
                return props.filteredDesk.filter(
                    (_x) => _x.gameTypeNo === 'G001'
                )
            } else if (!baccarat && dtiger && nui) {
                return props.filteredDesk.filter(
                    (_x) => _x.gameTypeNo === 'G001' || _x.gameTypeNo === 'G003'
                )
            } else if (!baccarat && dtiger && !nui) {
                return props.filteredDesk.filter(
                    (_x) => _x.gameTypeNo === 'G003'
                )
            }

            return props.filteredDesk
        })
        //#endregion

        return {
            optionNuiNuiChecked,
            optionBaccaratChecked,
            optionDragonTigerChecked,
            displayOptionLabel,
            displayOption,
            desksList,
            toggleDisplayOptionView,
            selectGameOption
        }
    }
})
