
import {
    ChipItem,
    computed,
    defineComponent,
    Emitter,
    inject,
    onMounted,
    PropType,
    ref,
    watch
} from 'vue'
import { SvgDolar } from '@/components/icons'
import { useStore } from '@/store'
import { abbreviateNumber } from '@/utils'
import { EventBuss } from '@/types/global'
import { MessageBoxActionType } from '@/types/MessageBox'
import { dragscroll } from 'vue-dragscroll'
import { AppMutation } from '@/store/types'

export default defineComponent({
    emits: ['update:modelValue'],
    directives: {
        dragscroll
    },
    props: {
        modelValue: {
            type: Object as PropType<ChipItem>
        }
    },
    components: {
        'svg-dolar': SvgDolar
    },
    setup(props, { emit }) {
        const emitter = inject('emitter') as Emitter
        const store = useStore()

        const activeChipId = ref(-1)

        onMounted(() => {
            if (chipDefault.value.length > 0) {
                if (userChip.value > 0) activeChipId.value = userChip.value
                else activeChipId.value = chipDefault.value[0].id
            }
        })

        const showChipDialog = () => {
            emitter.emit(EventBuss.DIALOG, {
                type: MessageBoxActionType.open,
                name: 'chips'
            })
        }

        const selectChip = (chipId: number) => {
            if (chipId !== activeChipId.value) {
                activeChipId.value = chipId
                store.commit(AppMutation.SET_CURRENT_CHIPS, chipId)
            }
        }

        const chipDefault = computed(
            (): ChipItem[] => store.getters['chipDefault']
        )
        const userChip = computed(() => store.getters['currentChip'])

        const coin = computed(() => store.getters['coin'])

        watch(
            () => activeChipId.value,
            (v) => {
                const chipValue = chipDefault.value.find((i) => i.id == v)
                if (chipValue) {
                    emit('update:modelValue', chipValue)
                }
            },
            { immediate: true }
        )

        watch(
            () => chipDefault.value,
            (v) => {
                setTimeout(() => {
                    const chipValue = chipDefault.value.find(
                        (i) => i.id == activeChipId.value
                    )
                    if (!chipValue && chipDefault.value.length > 0) {
                        activeChipId.value = chipDefault.value[0].id

                        // emit('update:modelValue', chipDefault.value[0])
                    }
                }, 100)
            }
        )

        return {
            chipDefault,
            activeChipId,
            coin,
            showChipDialog,
            abbreviateNumber,
            selectChip
        }
    }
})
